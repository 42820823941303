.breadcrumb {
  background-color: transparent !important;
  padding: 0;
  margin-bottom: 40px;

  li,
  li a {
    color: $gray;
    font-family: 'Montserrat-SemiBold', sans-serif;
    font-size: 15px;
    line-height: 18px;
    letter-spacing: 0.02em;
    position: relative;

    &:not(:last-child) {
      margin-right: 35px;
    }

    &.active {
      color: $orange;
    }
  }

  li:not(:last-child):before {
    position: absolute;
    content: '';
    top: 6px;
    right: -5px;
    border: 3.5px solid transparent;
    border-left: 6px solid $lightGray;
  }
}

@media (max-width: 768px) {
  .breadcrumb {
    margin: 10px 0 40px 0;

    li:not(:last-child),
    li a:not(:last-child) {
      margin-right: 20px;
    }
  }
}
