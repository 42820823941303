.blog__block {
  border-top: 10px solid $gray;
  padding: 100px 0 40px;
}

.wrapper__article {
  padding: 100px 0;

  h1 {
    margin-bottom: 60px;
    font-size: 60px;
  }

  .article__img {
    margin-bottom: 60px;
  }

  .article__body {
    h2 {
      margin-bottom: 40px;
    }

    h3 {
      margin: 40px 0px 60px;
      text-transform: none;
    }

    p {
      margin-bottom: 15px;
    }

    a {
      color: $black;
      text-decoration: underline;
      text-decoration-color: $orange;

      &:hover {
        color: $orange;
      }

      &:visited {
        color: $lightGray;
        text-decoration-color: $lightGray;
      }
    }

    .btn {
      width: fit-content;
    }

    iframe {
      border: 0;
      width: 100% !important;
    }

    img {
      display: block;
      max-width: 100%;
      height: auto;
    }

    ul {
      margin-left: 20px;
      margin-bottom: 40px;

      li {
        line-height: 180%;
        letter-spacing: 0.02em;
        padding-inline-start: 40px;
        position: relative;

        &::after {
          content: '';
          position: absolute;
          content: '';
          top: 8px;
          left: 10px;
          border: 6px solid transparent;
          border-left: 7px solid $lightGray;
        }
      }
    }

    .video__block {
      padding: 20px 0;
    }

    ol {
      margin-bottom: 40px;

      li {
        line-height: 180%;
        letter-spacing: 0.02em;
        padding-inline-start: 20px;
        counter-increment: list;
        list-style-type: none;
        position: relative;

        &::before {
          color: $lightGray;
          content: counter(list) '.';
          left: -32px;
          position: absolute;
          text-align: right;
          width: 26px;
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .wrapper__article {
    padding: 30px 0;

    h1 {
      margin-bottom: 30px;
      font-size: 26px;
    }
  }

  .blog__block {
    padding: 40px 0 40px;
  }
}
