.neighbors {
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;

  span.link-prev,
  span.link-next {
    display: block;
    margin-bottom: 30px;

    &.link-next {
      text-align: right;
    }

    a {
      color: $gray;
      font-family: "Montserrat-SemiBold", sans-serif;
      font-size: 13px;
      line-height: 100%;
      letter-spacing: 0.04em;
      text-transform: uppercase;
    }
  }
}

@media (max-width: 768px) {

  .neighbors span.link-prev.link-next,
  .neighbors span.link-next.link-next {
    text-align: left;
  }
}
