section.video__block {
  padding: 0;
  position: relative;
  width: 100%;
  height: 85vh;
  overflow: hidden;
  border-bottom: 10px solid #4e4c4b;
  &.slider-video {
    border-bottom: 10px solid #4e4c4b;
    bottom: 0;
  }

  a.video__block--btn {
    display: block;
    position: absolute;
    content: '';
    background-image: url(../images/general/video_arrow.svg);
    background-size: cover;
    background-repeat: no-repeat;
    height: 42px;
    width: 21px;
    bottom: 30%;
    right: 60px;
    z-index: 3;
  }

  .fullscreen-bg {
    position: relative;
    right: 0;
    bottom: 0;
    min-width: 100%;
    min-height: 100%;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: rgba(0, 0, 0, 0.5);
    }

    video {
      width: 100%;
      height: 100%;
      margin: 0;
      border-bottom: 10px solid $gray;
    }
  }

  .video__content {
    position: absolute;
    top: 35%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin: 0 auto;
    max-width: 1200px;
    width: 100%;
    z-index: 2;

    .title {
      color: $white;
      font-family: 'Montserrat-Bold', serif;
      font-size: 70px;
      line-height: initial;
      letter-spacing: 0.02em;
      margin-bottom: 15px;
    }

    .subtitle {
      color: #fa5838;
      font-family: 'Montserrat-SemiBold', serif;
      font-size: 30px;
      letter-spacing: 0.02em;
      padding-bottom: 60px;
    }

    .wrap__container {
      display: flex;
      justify-content: space-between;
      .btn_wrap {
        width: 50%;
        display: flex;
        align-items: flex-end;
        a {
          display: block;
          width: 230px;
          height: 60px;
          line-height: 60px;
          padding: 0;
        }
      }
      .description {
        width: 50%;
      }
    }

    .description {
      color: $white;
      font-size: 18px;
      line-height: 1.5;
      border-left: 2px solid;
      padding-left: 10px;
    }
  }

  .swiper-container-horizontal > .swiper-pagination-bullets,
  .swiper-pagination-custom,
  .swiper-pagination-fraction {
    bottom: 30%;
  }
}

.swiper-button-next,
.swiper-button-prev {
  height: 50px;
  width: 50px;
  top: 45% !important;
  background: #fff;
  box-shadow: 0 4px 18px rgba(73, 102, 93, 0.33);
  transition: all 0.5s ease;
  &:hover {
    box-shadow: 0 4px 35px rgba(73, 102, 93, 0.57);
  }
}

.s-top-slider {
  .slider-item {
    padding: 0;
  }
  .swiper-button-next,
  .swiper-button-prev {
    color: #fa5838;
    top: 40%;
    height: 50px;
    width: 50px;
    background: #fff;
    box-shadow: 0 4px 18px rgba(73, 102, 93, 0.23);
    transition: all 0.5s ease;
    &:hover {
      box-shadow: 0 4px 35px rgba(73, 102, 93, 0.37);
    }
  }
  .swiper-button-next {
    right: 35px;
    &:after {
      content: '►';
      font-size: 30px;
    }
  }
  .swiper-button-prev {
    left: 35px;
    &:after {
      content: '◄';
      font-size: 30px;
    }
  }
  .swiper-pagination {
    margin-bottom: 30px;
    &-bullet {
      background-color: #fff;
      border-radius: 0;
      opacity: 1;
      margin: 0 5px !important;
      height: 4px;
      width: 60px;
    }
    &-bullet-active {
      height: 10px;
      background: #fa5838;
      position: relative;
      top: 3px;
    }
  }
}

.project__item {
  margin-bottom: 30px;

  &--img {
    transition: 0.5s all;

    &:hover img {
      opacity: 0.7;
    }
  }

  &--item {
    overflow: hidden;

    .img-responsive {
      width: 100%;
    }
  }

  &--body {
    background: $light;
    padding: 30px;

    .top {
      margin-bottom: 30px;

      .title {
        font-size: 26px;
        line-height: 120%;
        text-transform: none;
      }

      .favorites {
        display: flex;
        align-items: center;
      }
    }

    .description {
      flex-wrap: wrap;
      margin-bottom: 20px;

      &__item {
        flex-basis: 33.3%;
        margin-bottom: 15px;

        .name {
          color: $orange;
        }
      }
    }

    .price {
      font-size: 20px;
      line-height: 150%;
      letter-spacing: 0.02em;
      margin-bottom: 30px;
    }

    .footer {
      a {
        color: white;
      }
    }
  }
}

section.about {
  background: $orange;
  border-bottom: 10px solid $gray;
  padding: 120px 0 60px;

  .about__image {
    margin-top: -210px;
  }

  .headtitle {
    color: $white;
    margin-bottom: 40px;
  }

  .subtitle {
    font-size: 30px;
    line-height: 120%;
    margin-bottom: 40px;
    text-transform: none;
  }

  p.big {
    font-size: 16px;
    margin-bottom: 30px;
  }

  .arrow--link {
    color: $white;

    &::before {
      border-left: 12px solid $white;
    }
  }
}

.slider-section {
  // max-width: 520px;
  .swiper-button-next,
  .swiper-button-prev {
    color: #000000;
    top: 40%;
  }
  .swiper-button-next {
    right: 35px;
    &:after {
      content: '►';
      font-size: 30px;
    }
  }
  .swiper-button-prev {
    left: 35px;
    &:after {
      content: '◄';
      font-size: 30px;
    }
  }
  .swiper-pagination {
    margin-bottom: 10px;
    &-bullet {
      background-color: #000000;
      border-radius: 0;
      opacity: 1;
      margin: 0 5px !important;
      height: 4px;
      width: 24px;
    }
    &-bullet-active {
      width: 25px;
      height: 10px;
      background: #fa5838;
      position: relative;
      top: 3px;
    }
  }
}

.slider-item {
  background: #f5f3ed;
  color: #000000;
  padding: 20px;
  &__photo {
    margin-bottom: 20px;
  }
  &__title {
    font-family: 'Montserrat-SemiBold', serif;
    font-size: 20px;
    margin-bottom: 10px;
  }
  &__position {
    color: #fa5838;
    font-family: 'Montserrat-SemiBold', serif;
    font-size: 13px;
    letter-spacing: 0.04em;
    margin-bottom: 20px;
    text-transform: uppercase;
  }
  &__info {
    font-size: 16px;
    letter-spacing: 0.02em;
    margin-bottom: 30px;
  }
}

.mt__slider {
  margin-top: -200px;
}

section.big-map {
  h2 {
    margin-bottom: 60px;
  }
}

section.consultation {
  background: $turquoise;
  border-bottom: 10px solid $gray;
  padding: 120px 0 60px;

  .map-content {
    margin: -210px auto 0;
    width: 520px;
    #map {
      height: 640px !important;
      width: 100%;
      margin-bottom: 20px;
    }
    &__title {
      font-size: 20px;
      letter-spacing: 0.02em;
      margin-bottom: 20px;
    }
    &__link {
      font-family: 'Montserrat-SemiBold', serif;
      font-weight: 600;
      font-size: 13px;
      letter-spacing: 0.04em;
      text-transform: uppercase;
    }
    .arrow--link::before {
      border-left: 12px solid #000;
    }
  }

  .headtitle {
    color: $orange;
    margin-bottom: 40px;
  }

  .subtitle {
    font-size: 34px;
    line-height: 120%;
    margin-bottom: 40px;
    text-transform: none;
  }

  button {
    margin-top: 40px;
  }
}

.home__item {
  margin-bottom: 60px;

  &--img {
    display: block;
    margin-bottom: 20px;
    transition: 0.5s all;

    &:hover {
      opacity: 0.8;
    }
  }

  &--info {
    .meta {
      margin-bottom: 15px;

      &__item {
        margin-right: 60px;

        .name {
          color: $orange;
          font-family: 'Montserrat-SemiBold', serif;
          font-size: 13px;
          line-height: 100%;
          letter-spacing: 0.04em;
          margin-right: 10px;
          text-transform: uppercase;
        }

        .value {
          color: $black;
          line-height: 150%;
          letter-spacing: 0.02em;
        }
      }
    }

    .title {
      text-transform: none;
      margin-bottom: 10px;
    }

    .location {
      display: flex;
      align-items: center;
      color: $gray;
      font-size: 16px;
      line-height: 150%;
      letter-spacing: 0.02em;

      span {
        margin-right: 12px;
      }
      a {
        color: $black;
        cursor: pointer;
        text-decoration-color: $orange;
        transition: color 0.3s ease;
      }
    }
  }

  &--full {
    .home__item--img {
      flex-basis: 60%;
      margin-bottom: 0;
    }

    .home__item--info {
      flex-basis: 40%;
      padding-left: 50px;
      margin: auto 0;
    }
  }

  &--left {
    .home__item--info {
      padding-left: 0;
    }
  }
}

section.s-advantages {
  padding: 100px 0 0;
  .section__title {
    margin-bottom: 30px;
  }
  .section__pretitle {
    text-transform: none;
  }
  .s-advantages-wrap {
    border-bottom: 10px solid #4e4c4b;
    margin-top: 150px;
    .slider-item__photo {
      margin-bottom: 40px;
    }
    .headtitle {
      font-size: 30px;
      line-height: 120%;
      letter-spacing: 0.02em;
      margin-bottom: 40px;
      text-transform: none;
    }

    .big {
      font-size: 16px;
      letter-spacing: 0.02em;
    }
    .arrow--link::before {
      top: -2px;
    }
  }
  .s-first {
    background: #daf1de;
    padding-top: 60px;
    padding-bottom: 60px;
    .slider-section {
      margin-top: -150px;
    }
  }
  .s-even {
    background: #daf1de;
    padding-top: 60px;
    padding-bottom: 60px;
  }
  .s-odd {
    background: #cbe6e3;
    padding-top: 60px;
    padding-bottom: 60px;
  }
}

section.s-technology {
  padding-bottom: 0;
  .s__title {
    margin-bottom: 60px;
  }
  .s-technology-wrap {
    .nav-tabs {
      border: 0;
      margin-bottom: 40px;
      .nav-item {
        margin-right: 30px;
        width: 180px;
        text-align: center;
        &:last-child {
          margin-right: 0;
        }
      }
      .nav-link {
        border: 2px solid #000000;
        border-radius: 0;
        color: #000;
        margin-bottom: 10px;
        &.active {
          transition: background 0.3s ease-in;
          color: #fff;
          border: 2px solid #fa5838;
          background: #fa5838;
        }
      }
    }
    .tab-content {
      background: #f5f3ed;
      padding: 40px 40px 0 40px;
      .tab__wrap {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
      }
      .left__col {
        color: #000000;
        width: 60%;
        .tab__title {
          font-family: 'Montserrat-SemiBold', serif;
          font-size: 34px;
          letter-spacing: 0.02em;
          margin-bottom: 40px;
        }
        .tab__text {
          font-size: 16px;
          letter-spacing: 0.02em;
          margin-bottom: 40px;
          max-width: 600px;
        }
        .arrow--link {
          color: #000000;
          &::before {
            top: -2px;
            border-left: 12px solid #000000;
          }
        }
      }
      .right__col {
        width: 40%;
        .slider-item__photo {
          margin-bottom: 40px;
        }
      }
    }
  }
}

section.reviews {
  padding: 90px 0 80px;

  .video-container,
  .video-mobile {
    padding-bottom: 20px;
    .swiper-button-next,
    .swiper-button-prev {
      color: #fa5838;
      top: 40%;
    }
    .swiper-button-next {
      right: 3px;
      top: initial !important;
      bottom: 3px;
      &:after {
        content: '►';
        font-size: 30px;
      }
    }
    .swiper-button-prev {
      left: 3px;
      top: initial !important;
      bottom: 3px;
      &:after {
        content: '◄';
        font-size: 30px;
      }
    }
    .swiper-pagination {
      margin-bottom: -15px;
      &-bullet {
        background-color: #000000;
        border-radius: 0;
        opacity: 1;
        margin: 0 5px !important;
        height: 4px;
        width: 25px;
      }
      &-bullet-active {
        height: 10px;
        background: #fa5838;
        position: relative;
        top: 3px;
      }
    }
  }

  .review__item {
    padding-bottom: 30px;
    .video__item,
    .review__item--link {
      display: block;
      position: relative;

      &--play {
        position: absolute;
        content: '';
        background-image: url(/assets/design/images/general/play.svg);
        background-size: cover;
        height: 60px;
        width: 60px;
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        &:hover {
          background-image: url(/assets/design/images/general/play--hover.svg);
        }
      }
    }

    &--img {
      margin-bottom: 20px;
    }
  }
}

.video-thumb__item {
  display: block;
  position: relative;

  .video-thumb__play {
    position: absolute;
    content: '';
    background-image: url(/assets/design/images/general/play.svg);
    background-size: cover;
    height: 60px;
    width: 60px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    &:hover {
      background-image: url(/assets/design/images/general/play--hover.svg);
    }
  }
}

section.marer-map {
  padding-bottom: 200px;
}

section.content-about {
  background: #cbe6e3;
  padding-bottom: 100px;
  .about__container {
    max-width: 1200px;
    margin: 0 auto;
  }
  .swiper-button-next,
  .swiper-button-prev {
    color: #000000;
    top: 40%;
  }
  .swiper-button-next {
    right: 35px;
    &:after {
      content: '►';
      font-size: 30px;
    }
  }
  .swiper-button-prev {
    left: 35px;
    &:after {
      content: '◄';
      font-size: 30px;
    }
  }
  .swiper-pagination {
    margin-bottom: -15px;
    &-bullet {
      background-color: #000000;
      border-radius: 0;
      opacity: 1;
      margin: 0 5px !important;
      height: 4px;
      width: 24px;
    }
    &-bullet-active {
      width: 25px;
      height: 10px;
      background: #fa5838;
      position: relative;
      top: 3px;
    }
  }
  .slider-about {
    margin-top: -210px;
    padding-bottom: 50px;
  }
  .content-about__wrap {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    .col-left,
    .col-right {
      width: 48%;
    }
    .col-right {
      padding-top: 210px;
      .headtitle {
        color: #fa5838;
        font-family: 'Montserrat-Bold', serif;
        font-size: 41px;
        margin-bottom: 40px;
      }

      .subtitle {
        font-family: 'Montserrat-SemiBold', serif;
        font-size: 30px;
        line-height: 120%;
        margin-bottom: 40px;
        text-transform: none;
      }

      p.big {
        font-size: 16px;
        margin-bottom: 30px;
      }

      .arrow--link {
        color: #000;
      }
      .arrow--link::before {
        border-left: 12px solid #000;
      }
    }
  }
}

@media (max-width: 768px) {
  section.consultation .subtitle {
    font-size: 26px;
  }

  section.video__block {
    border-bottom: 0;
    height: 100%;

    .fullscreen-bg {
      video {
        border-bottom: 0;
        height: 100%;
        width: 100vw;
      }
    }

    .video__content {
      top: 35%;

      .subtitle {
        font-size: 20px;
        padding-bottom: 0;
        padding-top: 0;
      }

      .title {
        font-size: 80px;
        margin-bottom: 20px;
        line-height: 120%;
        padding: 10px 0;
      }

      .description {
        font-size: 24px;
        margin-top: 45px;
      }
    }

    a.video__block--btn {
      height: 32px;
      width: 14px;
    }
  }

  .project__item {
    &--body {
      .top {
        margin-bottom: 20px;

        .title {
          font-size: 18px !important;
          line-height: 120%;
        }
      }

      .description {
        flex-wrap: wrap;
        margin-bottom: 20px;

        &__item {
          flex-basis: 50%;
          width: 50%;
          margin-bottom: 20px;

          .name {
            font-size: 10px;
            line-height: 100%;
          }

          .value {
            font-size: 12px;
            line-height: 150%;
            letter-spacing: 0.02em;
          }

          &:last-child {
            margin-bottom: 0;
          }
        }
      }

      .price {
        font-size: 15px;
        line-height: 150%;
        margin-bottom: 25px;
      }
    }
  }

  section.about {
    .about__image {
      margin-bottom: 40px;
    }

    .headtitle {
      font-size: 20px;
      line-height: 122%;
      margin-bottom: 15px;
    }

    .subtitle {
      font-size: 18px;
      line-height: 120%;
      margin-bottom: 20px;
    }

    p.big {
      font-size: 15px;
      line-height: 150%;
      margin-bottom: 45px;
    }
  }

  section.consultation {
    .consultation__image {
      margin-bottom: 40px;
    }
  }

  .home__item {
    margin-bottom: 30px;

    &--img {
      margin-bottom: 15px;
    }

    &--info {
      .meta {
        margin-bottom: 10px;

        &__item {
          margin-right: 25px;

          .name {
            font-size: 12px;
            line-height: 100%;
          }

          .value {
            font-size: 12px;
            line-height: 100%;
          }
        }
      }

      .title {
        font-size: 18px;
        line-height: 120%;
        margin-bottom: 0;
      }

      .location {
        font-size: 11px;
        line-height: 150%;

        span {
          margin-right: 12px;
        }
      }
    }

    &--full {
      .home__item--img {
        flex-basis: 100%;
        margin-bottom: 15px;
      }

      .home__item--info {
        flex-basis: 100%;
        padding-left: 0;
        margin: 0;
      }
    }
  }
}

@media (max-width: 500px) {
  h2 {
    font-size: 30px !important;
  }
  section.consultation .subtitle {
    font-size: 26px;
  }

  section.video__block {
    .container,
    .col-12 {
      padding-left: 0;
      padding-right: 0;
    }
    .row {
      margin: 0;
    }
    border-bottom: 0;

    .swiper-button-next,
    .swiper-button-prev {
      display: none;
    }
    .swiper-container-horizontal > .swiper-pagination-bullets,
    .swiper-pagination-custom,
    .swiper-pagination-fraction {
      bottom: 10% !important;
    }

    .swiper-pagination {
      text-align: left;
      left: 5%;
    }

    .swiper-pagination-bullet {
      width: 30px !important;
    }
    a.video__block--btn {
      bottom: 15%;
      right: 5%;
    }

    .fullscreen-bg {
      video {
        border-bottom: 0;
        height: 100%;
        width: 100vw;
      }
    }

    .video__content {
      top: 120px;
      left: 0;
      transform: none;
      text-align: center;
      .title {
        font-size: 30px !important;
        text-transform: uppercase;
        margin-bottom: 0;
      }
      .subtitle {
        font-size: 18px !important;
        margin-bottom: 30px !important;
      }
      .wrap__container {
        flex-direction: column;
        .btn_wrap {
          width: 100%;
          justify-content: center;
        }
        .description {
          display: none;
        }
      }
      .subtitle {
        font-size: 15px;
        padding-bottom: 0;
        padding-top: 0;
        margin-bottom: 20px;
      }

      .title {
        font-size: 34px;
        line-height: 120%;
        padding: 10px 0;
      }

      .description {
        font-size: 15px;
        margin-top: 45px;
      }
    }

    a.video__block--btn {
      height: 32px;
      width: 14px;
    }
  }
  .project__item--body {
    padding: 30px 20px;
  }

  .project__item--body .top {
    .title {
      font-size: 15px !important;
    }
    a.msfavorites,
    .voted + .go-msfavorites {
      font-size: 9px !important;
    }
  }

  section.consultation {
    .headtitle {
      margin-bottom: 30px;
    }
    .subtitle {
      font-size: 20px;
    }
    button {
      margin-top: 10px;
    }
  }

  section.about {
    .headtitle {
      font-size: 20px !important;
      margin-top: 30px;
    }
  }

  section.s-advantages {
    .s-odd {
      .left_s {
        order: 2;
      }
      .right_s {
        order: 1;
      }
    }
    .s-advantages-wrap {
      .headtitle {
        font-size: 16px !important;
        margin-top: 30px !important;
      }
    }
    .big {
      font-size: 14px !important;
    }
  }

  section.s-technology {
    .info {
      margin-top: 30px;
    }
    .s-technology-wrap .tab-content {
      padding: 30px 20px;
      .tab__wrap {
        flex-direction: column;
        .left__col {
          order: 2;
          width: 100%;
        }
        .right__col {
          order: 1;
          width: 100%;
          .tab__title {
            margin-bottom: 20px;
          }
        }
      }
    }
  }
}
