.wrapper__sovet {
  .title__wrap {
    padding-bottom: 60px;
  }
}

.info__block {
  margin-bottom: 60px;

  .subtitle {
    margin-bottom: 40px;
  }

  .title {
    margin-bottom: 40px;
  }

  .info__block--text {
    margin-bottom: 90px;

    p {
      line-height: 150%;
    }
  }

  .logotype {
    margin: 0 auto;
  }

  .infoblock__item {
    &--img {
      margin: 0 auto;
    }

    &--body {
      background: $light;
      padding: 30px;

      p.big.title {
        margin-bottom: 30px;
      }

      .text {
        p.big.title {
          margin-bottom: 20px;
        }

        span {
          display: block;

          &.name {
            color: $orange;
            font-family: 'Montserrat-SemiBold', sans-serif;
            font-size: 13px;
            line-height: 100%;
            margin-bottom: 10px;
            text-transform: uppercase;
          }
        }

        .value {
          margin-bottom: 0;
        }
      }
    }
  }
}

.video__block {
  padding: 100px 0px 40px;

  .video__item {
    cursor: pointer;
    display: block;
    position: relative;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3));

    &::before {
      position: absolute;
      content: '';
      background-image: url(../images/general/play.svg);
      background-size: cover;
      height: 60px;
      width: 60px;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
    &:hover::before {
      background-image: url(../images/general/play--hover.svg);
    }
  }
}

.partners {
  padding-bottom: 100px;

  .title {
    margin-bottom: 40px;
  }

  &__wrap {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;

    .partner__item {
      flex-basis: 20%;

      img {
        margin: 0 auto;
      }
    }
  }
}

@media (max-width: 768px) {
  .wrapper__sovet {
    .title__wrap {
      padding-bottom: 30px;
    }
  }

  .info__block {
    .subtitle {
      margin-bottom: 30px;
    }

    .title {
      margin-bottom: 20px;
    }

    .logotype {
      margin-bottom: 20px;

      img {
        max-width: 200px;
      }
    }

    .image {
      margin-bottom: 30px;
    }

    .info__block--text {
      margin-bottom: 30px;
    }

    .infoblock__item {
      margin-bottom: 30px;

      .infoblock__item--body {
        padding: 20px;

        .text {
          span.name {
            font-size: 10px;
            line-height: 100%;
          }
        }
      }
    }
  }

  .video__block {
    padding: 10px 0 0;

    .video__item {
      &::before {
        height: 50px;
        width: 50px;
      }
    }
  }

  .partners {
    padding-bottom: 60px;

    .title {
      margin-bottom: 20px;
    }

    &__wrap {
      .partner__item {
        flex-basis: 50%;
      }
    }
  }
}
