.jobs {
  padding-bottom: 0;

  .total {
    margin-bottom: 40px;
    color: $gray;
  }

  .job__item {
    &:not(last-child) {
      margin-bottom: 100px;
    }

    &--title {
      margin-bottom: 40px;
    }

    &--duties {
      color: $orange;
      margin-bottom: 20px;
    }

    ul.list--marked {
      margin-bottom: 40px;
    }
  }
}

@media (max-width: 768px) {
  .jobs {
    .job__item {
      &:not(last-child) {
        margin-bottom: 40px;
      }

      &--title {
        margin-bottom: 30px;
      }

      ul.list--marked {
        margin-bottom: 30px;
      }

      .btn {
        width: 100%;
      }

    }
  }
}
