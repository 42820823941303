.bg-turquoise__block {
  background: $turquoise;
  border-bottom: 10px solid $gray;
  padding: 60px 0 100px;

  .headtitle {
    margin-bottom: 40px;
  }

  .video__block {
    padding: 60px 0 0;
  }
}

.text-info__block {
  padding: 100px 0 200px;


  h3 {
    margin-bottom: 40px;
    text-transform: none;
  }

  p {
    margin-bottom: 30px;
  }

  ul {
    margin-left: 20px;
    margin-bottom: 30px;

    li {
      line-height: 180%;
      letter-spacing: 0.02em;
      padding-inline-start: 40px;
      position: relative;

      &::after {
        content: '';
        position: absolute;
        content: '';
        top: 8px;
        left: 10px;
        border: 6px solid transparent;
        border-left: 7px solid #A2A7A9;
      }
    }
  }

  .video__block {
    padding: 60px 0 0;
  }
}

.bg-orange__block {
  background: $orange;
  border-bottom: 10px solid $gray;
  padding: 60px 0 100px;

  .headtitle {
    color: white;
    margin-bottom: 40px;
  }

  .subtitle {
    margin-bottom: 40px;
  }

  ul {
    margin-left: 20px;
    margin-bottom: 30px;

    li {
      line-height: 180%;
      letter-spacing: 0.02em;
      padding-inline-start: 40px;
      position: relative;

      &::after {
        content: '';
        position: absolute;
        content: '';
        top: 8px;
        left: 10px;
        border: 6px solid transparent;
        border-left: 7px solid white;
      }
    }
  }
}

.bg-light__block {
  background: $light;
  border-bottom: 10px solid $gray;
  padding: 100px 0 100px;

  .headtitle {
    margin-bottom: 40px;
  }
}

@media (max-width: 768px) {
  .bg-turquoise__block {
    padding-bottom: 40px;

    .headtitle {
      margin-bottom: 20px;
    }

    .video__block {
      padding: 10px 0 0;
    }
  }

  .text-info__block {
    padding: 60px 0 160px;

    h3 {
      margin-bottom: 20px;
    }

    p {
      margin-bottom: 20px;
    }

    ul {
      margin-left: 0;
      margin-bottom: 20px;

      li {
        font-size: 11px;
      }

      p {
        margin-bottom: 1rem;
      }

      .video__block {
        padding: 10px 0 0;
      }
    }
  }

  .map__wrap {
    margin-bottom: 40px;
  }

  .bg-orange__block,
  .bg-light__block {
    padding-bottom: 40px;

    .headtitle,
    .subtitle {
      margin-bottom: 20px;
    }

    ul li {
      font-size: 11px;
    }
  }

  .pb__100 {
    padding-bottom: 40px !important;
  }
}
