.contacts__wrap {
  background: $light;
  border-bottom: 10px solid $gray;
  padding: 60px 0 100px;

  .headtitle {
    margin-bottom: 60px;
    text-transform: none;
  }

  .subtitle {
    color: $orange;
    margin-bottom: 20px;
  }

  .contacts__block {
    margin-bottom: 40px;

    .contacts__block--item {
      display: flex;

      .name {
        font-family: 'Montserrat-Bold', serif;
        font-size: 16px;
        line-height: 200%;
        letter-spacing: 0.02em;
        width: 300px;
      }

      .info {
        line-height: 200%;
        letter-spacing: 0.02em;

        a {
          color: $black;
          display: block;
          line-height: 200%;
          text-decoration: underline;
          text-decoration-color: $orange;
          transition: color 0.3s ease;

          &:hover {
            color: $orange;
          }
        }
      }
    }
  }

  .write-social {
    margin-bottom: 40px;

    .whatsapp a,
    .viber a,
    .telegram a {
      color: $black;
    }
  }
}

.locations {
  .location__block {
    display: flex;
    align-items: center;
    margin-bottom: 20px;

    .ic__loc {
      margin-right: 15px;
    }

    .name__loc {
      font-family: 'Montserrat-Bold', serif;
      font-size: 16px;
      line-height: 150%;
    }
  }

  .location__block--wrap {
    padding-left: 30px;

    .location__block--clock {
      margin-bottom: 40px;

      span {
        font-family: 'Montserrat-Bold', serif;
        font-size: 16px;
        line-height: 150%;
      }

      font-size: 16px;
      line-height: 150%;
    }

    .location__block--address {
      margin-bottom: 40px;

      span {
        display: block;
        line-height: 150%;
      }
    }

    .location__block--contact {
      span {
        margin-right: 10px;
      }

      a {
        color: $black;
        line-height: 150%;
        letter-spacing: 0.02em;
        transition: color 0.3s ease;

        &:hover {
          color: $orange;
        }

        &.mail {
          text-decoration: underline;
          text-decoration-color: $orange;
        }
      }
    }
  }
}

.map {
  border-bottom: 10px solid $gray;
  padding: 100px 0;
}

.blank {
  padding: 100px 0 100px;
}

section.requisites {
  .headtitle {
    margin-bottom: 40px;
    text-transform: none;
  }

  .download {
    display: flex;
    align-items: center;

    span {
      margin-right: 20px;
    }

    a {
      color: $black;
      font-family: 'Montserrat-SemiBold', serif;
      font-size: 13px;
      line-height: 100%;
      letter-spacing: 0.04em;
      text-transform: uppercase;
    }
  }
}

@media (max-width: 768px) {
  .contacts__wrap {
    padding: 60px 0;

    .headtitle {
      margin-bottom: 40px;
    }

    .contacts__block {
      .contacts__block--item {
        .name {
          font-size: 11px;
          width: 40%;
        }

        .info {
          font-size: 11px;
          width: 60%;

          a {
            font-size: 11px;
          }
        }
      }
    }
  }

  .blank {
    display: none;
  }

  .map {
    padding: 20px 0 120px;

    .location__block {
      margin-top: 40px;

      .ic__loc {
        margin-right: 10px;
      }

      .name__loc {
        font-size: 11px;
      }
    }
  }

  .locations {
    .location__item {
      &:first-child {
        margin-bottom: 20px;
      }
    }

    .location__block--wrap {
      padding-left: 30px;

      .location__block--clock {
        margin-bottom: 20px;

        span {
          font-size: 11px;
        }

        font-size: 11px;
      }

      .location__block--address {
        margin-bottom: 20px;

        span {
          font-size: 11px;
        }
      }

      .location__block--contact {
        a {
          font-size: 11px;
        }
      }
    }
  }

  section.requisites {
    padding: 60px 0;

    .headtitle {
      margin-bottom: 30px;
    }

    .download {
      span {
        margin-right: 10px;
      }

      a {
        font-size: 10px;
        line-height: 100%;
      }
    }
  }
}
