.project {
  border-bottom: 10px solid $gray;
  padding-bottom: 100px;
}

.project__item--body .top .favorites,
.s__title .favorites {
  width: auto;
}

.project__description {
  margin-bottom: 100px;

  .margin-b {
    margin-bottom: 30px;
  }

  &.project__description--nomargin {
    margin-bottom: 0;
  }

  &--top {
    background: $turquoise;
    padding: 25px 50px;

    .icon__box {
      color: #000;
      display: flex;
      align-items: center;
      font-size: 20px;
      line-height: 150%;
      transition: color 0.3s ease;

      span {
        margin-right: 15px;
      }
      .arrow--link::before {
        top: -5px;
      }

      &:hover {
        color: $orange;
        text-decoration: none;
      }
    }
  }

  &--bottom {
    background: $light;
    padding: 50px 50px;

    .smeta {
      margin-top: 15px;
    }

    .description__list {
      display: flex;
      flex-wrap: wrap;

      .description__item {
        flex-basis: 50%;
        margin-bottom: 30px;

        .name {
          color: $orange;
          font-family: 'Montserrat-SemiBold', serif;
          font-size: 13px;
          line-height: 100%;
          letter-spacing: 0.04em;
          margin-right: 10px;
          text-transform: uppercase;
        }

        .value,
        .value a {
          color: $black;
          line-height: 150%;
          letter-spacing: 0.02em;
        }
      }
    }

    .index__box {
      span {
        margin-bottom: 10px;
      }

      .number {
        font-family: 'Montserrat-SemiBold';
        font-size: 34px;
        line-height: 120%;

        span {
          margin-bottom: 0;
        }
      }
    }

    .inrotext {
      margin-bottom: 40px;
    }

    .price {
      font-family: 'Montserrat-SemiBold';
      font-size: 34px;
      line-height: 120%;
      margin-bottom: 35px;
    }

    .btn__wrap {
      margin-bottom: 40px;

      .btn {
        width: 280px;

        &.smeta {
          margin-bottom: 20px;
        }
      }
    }

    .social__wrap {
      .messagers {
        display: flex;
        align-items: center;

        .headtitle {
          margin-right: 15px;
          font-size: 14px;
        }

        .write-social {
          align-items: end;

          .whatsapp,
          .viber,
          .telegram,
          .vkontakte,
          .facebook {
            margin-bottom: 0;
            margin-right: 10px;

            .ic__whatsapp,
            .ic__viber,
            .ic__telegram {
              width: 24px;
              height: 24px;
            }

            .ic__fb {
              width: 18px;
              height: 24px;
            }

            .ic__vk {
              width: 31px;
              height: 22px;
            }
          }
        }
      }
    }
  }
}

.plane__wrap {
  margin-top: 25px;

  .plane__box {
    margin-bottom: 20px;

    h4 {
      color: $gray;
      font-family: 'Montserrat-SemiBold', serif;
      font-weight: 600;
      font-size: 13px;
      line-height: 100%;
      margin-bottom: 30px;
    }
  }
  .plane-plus {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 60px;
    height: 60px;
    background: #4e4c4b;
    display: flex;
    align-items: center;
    justify-content: center;
    &__icon {
      display: block;
      color: #ffffff;
      font-size: 40px;
    }
  }
}

.fotorama__video-play {
  left: 50%;
  top: 50%;
  width: 60px !important;
  height: 60px !important;
  margin-left: -48px;
  margin-top: -48px;
  background-position: initial;
  opacity: 0;
  background-size: cover;
  background: url(/assets/design/images/general/play.svg);
  background-repeat: no-repeat;
  &:hover {
    background: url(/assets/design/images/general/play--hover.svg) !important;
    background-repeat: no-repeat;
  }
}

.v__mobile {
  display: none !important;
}

@media (max-width: 769px) {
  section.project {
    padding-top: 30px;
  }

  .h__mobile {
    display: none;
  }

  .index__box.v__mobile {
    display: flex !important;
    justify-content: flex-start;

    .block {
      width: 50%;
    }
  }
}

@media (max-width: 768px) {
  .h__mobile {
    display: none;
  }

  .index__box.v__mobile {
    display: flex !important;
    justify-content: space-between;

    .block__price {
      display: flex;
      align-items: flex-end;

      .price {
        margin-bottom: 0;
      }
    }
  }

  .project__description--top {
    padding: 30px 20px 10px;

    .icon__box {
      font-size: 15px;
      margin-bottom: 20px;
    }
    .margin-b {
      margin-bottom: 0;
    }
  }

  .ic__garantee {
    width: 30px;
    height: 30px;
  }

  .ic__price {
    width: 30px;
    height: 32px;
  }

  .project__description {
    margin-bottom: 40px;
  }

  .project__description--bottom {
    padding: 30px 20px;

    .index__box {
      span {
        font-size: 10px;
      }

      .number {
        font-size: 18px;

        span {
          margin-bottom: 0;
        }
      }
    }

    .inrotext {
      margin-top: 15px;
    }

    .description__list .description__item {
      margin-bottom: 20px;

      .name {
        font-size: 10px;
        line-height: 100%;
      }

      .value {
        font-size: 12px;
        line-height: 150%;
      }
    }

    .price {
      font-size: 18px;
      line-height: 120%;
      margin-bottom: 20px;
    }

    .btn {
      font-size: 13px;
      margin-right: 0;
      width: 100% !important;
    }
  }
}
