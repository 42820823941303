.blog__wrap {
  .title__wrap {
    padding: 100px 0 60px;
  }
}

.filter {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  background: $turquoise;
  padding: 50px 40px;
  margin-bottom: 60px;

  &.filter__blog {
    fieldset {
      display: contents;
    }
  }

  .title {
    font-family: 'Montserrat-SemiBold', sans-serif;
    font-size: 13px;
    line-height: 100%;
    letter-spacing: 0.04em;
    margin-right: 20px;
    text-transform: uppercase;
    margin-bottom: 0.5rem;
  }

  button[type='reset'] {
    margin-right: 40px;
    margin-bottom: 0.5rem;
    display: inline-block;
    background: transparent;
    color: black !important;
    cursor: pointer;
    height: 40px;
    padding-top: 10px;
    padding-bottom: 12px;
    line-height: initial;
    text-align: center;
    width: 180px;
    border: 2px solid #4e4c4b;
    box-sizing: border-box;

    &:focus {
      box-shadow: none;
    }

    &.hidden {
      border: 2px solid #fa5838;
      background: #fa5838;
      transition: background 0.3s ease-in;
    }
  }

  label {
    margin-right: 40px;
    margin-bottom: 0.5rem;

    &:last-child {
      margin-right: 0;
    }
  }

  span.label__title {
    display: inline-block;
    color: black;
    cursor: pointer;
    height: 40px;
    padding-top: 10px;
    padding-bottom: 12px;
    line-height: initial;
    text-align: center;
    width: 180px;
    border: 2px solid $gray;
    box-sizing: border-box;
  }

  input {
    display: none;
  }

  input:checked + span {
    border: 2px solid $orange;
    background: $orange;
    color: white;
    transition: background 0.3s ease-in;
  }
}

.mfp-fade.mfp-bg {
  opacity: 0;
  transition: all 0.15s ease-out;
}

.mfp-fade.mfp-bg.mfp-ready {
  opacity: 0.8;
}

.mfp-fade.mfp-bg.mfp-removing {
  opacity: 0;
}

.mfp-fade.mfp-wrap .mfp-content {
  opacity: 0;
  transition: all 0.15s ease-out;
}

.mfp-fade.mfp-wrap.mfp-ready .mfp-content {
  opacity: 1;
}

.mfp-fade.mfp-wrap.mfp-removing .mfp-content {
  opacity: 0;
}

.popup-player {
  display: block;
  position: relative;

  .video__item--play {
    position: absolute;
    content: '';
    background-image: url(/assets/design/images/general/play.svg);
    background-size: cover;
    height: 60px;
    width: 60px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    &:hover {
      background-image: url(/assets/design/images/general/play--hover.svg);
    }
  }
}

.blog__item {
  margin-bottom: 60px;
  cursor: pointer;

  .video__item,
  .popup-youtube,
  .video-btn {
    display: block;
    position: relative;

    &--play {
      position: absolute;
      content: '';
      background-image: url(/assets/design/images/general/play.svg);
      background-size: cover;
      height: 60px;
      width: 60px;
      top: 50%;
      left: 50%;
      -webkit-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
      &:hover {
        background-image: url(/assets/design/images/general/play--hover.svg);
      }
    }
  }

  .blog__item--img {
    margin-bottom: 13px;
    display: block;
    transition: 0.5s all;

    &:hover {
      opacity: 0.8;
    }

    &.video {
      display: block;
      position: relative;
      background: linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3));

      &::before {
        position: absolute;
        content: '';
        background-image: url(../images/general/play.svg);
        background-size: cover;
        height: 60px;
        width: 60px;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
      &:hover::before {
        background-image: url(../images/general/play--hover.svg);
      }
    }
  }

  .blog__item--info {
    display: flex;
    align-items: center;
    margin-bottom: 15px;

    .blog__item--date {
      color: $orange;
      margin-right: 60px;
      margin-bottom: 0;
    }

    .blog__item--category {
      line-height: 150%;
      letter-spacing: 0.02em;
    }
  }

  .blog__item--title {
    a {
      color: #000000;

      &:hover {
        opacity: 0.7;
        text-decoration: none;
        transition: 0.3s all;
      }
    }
  }
}

@media (max-width: 768px) {
  .blog__wrap .title__wrap {
    padding: 40px 0 40px;
  }

  .filter {
    padding: 30px 20px;
    margin-bottom: 40px;

    span.title {
      display: block;
      margin-bottom: 15px;
    }

    label {
      display: block;
      margin-right: 0;
      margin-bottom: 20px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    span.label__title {
      height: 30px;
      font-size: 11px;
      padding-top: 6px;
    }

    button[type='reset'] {
      height: 30px;
      font-size: 11px;
      padding-top: 6px;
      margin-bottom: 20px;
    }
  }

  .blog__item {
    margin-bottom: 30px;

    .blog__item--img {
      margin-bottom: 15px;
    }

    .blog__item--info {
      .blog__item--date {
        margin-right: 70px;
      }

      .blog__item--category {
        font-size: 11px;
      }
    }
  }
}
