.text__block {
  padding: 70px 0px 100px;

  ul {
    margin-left: 20px;
    margin-bottom: 30px;

    li {
      line-height: 180%;
      letter-spacing: 0.02em;
      padding-inline-start: 40px;
      position: relative;

      &::after {
        content: '';
        position: absolute;
        content: '';
        top: 8px;
        left: 10px;
        border: 6px solid transparent;
        border-left: 7px solid #A2A7A9;
      }
    }
  }
}

#mse2_filters .mse2_number_slider {
  font-size: .5em !important;
}

.ui-widget-header {
  background: $orange !important;
}

.ui-widget-content {
  background: #4E4C4B !important;
}

.ui-state-default,
.ui-widget-content .ui-state-default {
  &::before {
    position: absolute;
    content: '';
    background-image: url(../images/general/ic_area_slider.svg);
    background-size: cover;
    height: 21px;
    width: 21px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.ui-state-hover,
.ui-widget-content .ui-state-hover {
  outline: 0 !important;
}

#mse2_filters {
  .form-group .input-sm {
    padding: 19px 16px;
    text-align: center;
    width: 60%;
  }
}

.projects__wrap {
  .title__wrap {
    padding: 100px 0 20px;
  }
}

.filter__projects {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start !important;
  flex-wrap: wrap;
  margin-bottom: 30px !important;

  .col {
    flex-basis: 25%;

    &:last-child {
      flex-direction: row;

      label:not(:last-child) {
        margin-bottom: 0;
        margin-right: 10px;
      }

      span.label__title {
        width: 40px;
      }

      &.bedroom {
        label {
          width: auto;
          display: inline-block;
          margin-bottom: 15px;
        }
      }
    }

    .title {
      display: block;
      margin-bottom: 20px;
    }

    label:not(:last-child) {
      margin-bottom: 20px;
    }
  }
}

button.reset {
  margin-bottom: 60px;
}

.total__wrap {
  margin-bottom: 40px;
}

@media (max-width: 768px) {
  .projects__wrap .title__wrap {
    padding: 40px 0 40px;
  }

  .text__block {
    padding: 30px 0px 60px;

    ul li {
      font-size: 11px;
    }
  }

  .filter__projects {

    .col {
      flex-basis: 50%;
      margin-bottom: 25px;

      label:not(:last-child) {
        margin-bottom: 10px;
      }
    }
  }

  #mse2_filters .form-group .input-sm {
    padding: 7px 16px;
    width: 60%;
    font-size: 11px;
  }
}

@media (max-width: 500px) {
  .filter__projects {

    .col {
      flex-basis: 100%;
    }
  }
}
