.pagination {
  .page-link {
    border-radius: 0;
    padding: 0;
    background-color: transparent;
    border: 0;
    color: #005b7f;
    font-size: 18px;
    margin-right: 5px;

    &:hover {
      opacity: .8;
      transition: 0.7s all ease;
    }
  }
}