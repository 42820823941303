.btn {
  background-color: $gray;
  border-radius: 0;
  color: white !important;
  display: block;

  font-family: 'Montserrat-SemiBold', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 100%;
  padding-top: 24px;
  padding-bottom: 23px;
  text-align: center;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  transition: background 0.3s ease;
  height: 60px;
  width: 240px;
  text-decoration: none !important;

  &:hover {
    background: $black;
    color: $white;
  }

  &:focus {
    outline: 0;
  }
}

.btn__center {
  margin: 0 auto;
}

.btn__right {
  float: right;
}

.btn--orange {
  background-color: $orange;

  &:hover {
    background-color: $red;
  }
}

.btn--white {
  background-color: white;
  color: #000000 !important;
  &:hover {
    background-color: $red;
    color: white !important;
  }
}

@media (max-width: 768px) {
  .btn {
    font-size: 10px;
    height: 50px;
    line-height: 100%;
    padding-top: 20px;
    padding-bottom: 20px;
  }
}
