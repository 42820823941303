form {
  width: 100%;

  &#contactForm label {
    width: 100%;
  }

  .form-group {
    margin-bottom: 20px;
  }

  label {
    margin-bottom: 0;
  }

  .introtext {
    margin-bottom: 15px;

    p {
      font-size: 13px;
    }
  }

  .form-control {
    box-sizing: border-box;
    font-weight: 500;
    font-size: 16px;
    line-height: 150%;
    letter-spacing: 0.02em;
    color: $black; // Accessibility
    padding: 24px 16px;
    border: 1px solid #4e4c4b;
    border-radius: 0;
    background-color: transparent;
    margin-bottom: 0;

    &.error {
      border-color: $orange;
    }

    &:focus {
      border-color: $orange;
      box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.102764) !important;
      box-shadow: 0px 4px 17.28px 0.72px rgba(58, 58, 58, 0.45);
    }
  }

  .qq-upload-button {
    cursor: pointer;
    box-sizing: border-box;
    font-weight: 500;
    font-size: 16px;
    line-height: 50px;
    letter-spacing: 0.02em;
    color: #495057;
    padding: 0 16px 0 47px;
    border: 1px solid #4e4c4b;
    border-radius: 0;
    background-color: transparent;
    margin-bottom: 0;
    height: 50px;
    width: 244px;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
      -webkit-box-shadow 0.15s ease-in-out;
    position: relative;
    text-align: left;

    &:hover {
      background: transparent;
    }

    &::before {
      position: absolute;
      content: "";
      background-image: url(../images/general/ic_file_upload.svg);
      background-size: cover;
      height: 18px;
      width: 14px;
      left: 18px;
      top: 15px;
    }
  }

  .qq-clear-button {
    display: none;
  }

  .file-uploader-items {
    margin-top: 20px;

    .file-wrap {
      float: none;

      &:last-child {
        margin-bottom: 0;
      }

      .thumb {
        display: none;
      }

      .title {
        display: block;
        font-size: 12px;
      }
    }
  }
}

#thanks {
  .modal-body {
    padding: 0;

    .thanks__header {
      background: $orange;
      padding: 1rem 2rem 1rem 1rem;

      .title {
        color: white;
        line-height: initial;
      }
    }

    .thanks__body {
      padding: 1rem;

      .subtitle {
        margin-bottom: 15px;
        line-height: initial;

        br {
          display: none;
        }
      }

      .video {
        iframe {
          width: 100% !important;
          height: auto;
          min-height: 184px;
        }
      }
    }
  }

  .thanks__middle {
    border-top: 10px solid $orange;
    background-color: #4e4c4b;
    display: flex;
    padding: 1rem;

    &--photo {
      flex-basis: 30%;
    }

    &--text {
      flex-basis: 70%;
      color: white;

      .name {
        display: block;
        font-size: 13px;
        font-weight: 700;
      }
    }
  }

  .thanks__footer {
    padding: 1rem;
  }
}

#ModalVideo,
#ReviewVideoFile {
  .modal-dialog {
    max-width: 800px;
    margin: 30px auto;
  }

  .modal-body {
    position: relative;
    padding: 0px;
  }

  .modal-content {
    background: transparent;
  }
}

@media (max-width: 768px) {
  .modal {
    padding-left: 0 !important;
  }

  form label {
    width: 100%;
  }

  form .qq-upload-button {
    width: 100%;
  }
}

@media (max-width: 360px) {
  #thanks .modal-body {
    .thanks__header {
      background: #fa5838;
      padding: 0.8rem 2rem 0.8rem 1rem;

      .title {
        color: white;
        line-height: initial;
        font-size: 13px;
      }
    }
  }

  .subtitle {
    margin-bottom: 15px;
    line-height: initial;
    font-size: 14px;
  }

  .video iframe {
    min-height: 160px;
  }
}
