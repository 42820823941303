.gallery__wrap {
  .title__wrap {
    padding: 100px 0 60px;
  }
}

@media (max-width: 768px) {
  .gallery__wrap .title__wrap {
    padding: 40px 0 40px;
  }

  .home__item--full {
    display: flex;
    flex-wrap: wrap;

    .home__item--info {
      order: 2;
    }

    .home__item--img {
      order: 1;
    }
  }
}
