.project-modal {
  .modal-dialog {
    max-width: 800px;
  }
  .modal-header {
    padding: 0;
    position: relative;
    .close {
      position: absolute;
      top: 30px;
      right: 30px;
      height: 60px;
      width: 60px;
      background: #fa5838;
      color: white;
      font-size: 40px;
    }
  }
  .modal-body {
    color: #000;
    padding: 40px 60px 60px;
    .title {
      font-family: 'Montserrat-SemiBold';
      font-size: 34px;
      letter-spacing: 0.02em;
      margin-bottom: 30px;
    }
    .text {
      font-weight: 500;
      font-size: 16px;
      letter-spacing: 0.02em;
    }
  }
}
