/*==========  Desktop First  ==========*/

// ≤1200px
@include media-breakpoint-down(lg) {
}

@media (max-width: 1441px) {
  section.video__block {
    height: auto;
    border-bottom: 0;
    .swiper-container-horizontal > .swiper-pagination-bullets,
    .swiper-pagination-custom,
    .swiper-pagination-fraction {
      bottom: 10%;
    }
    .swiper-button-next,
    .swiper-button-prev {
      top: 70% !important;
    }
    a.video__block--btn {
      bottom: 10%;
    }
  }
}
@media screen and (max-width: 1366px) and (max-height: 769px) {
  section.video__block {
    .swiper-container-horizontal > .swiper-pagination-bullets,
    .swiper-pagination-custom,
    .swiper-pagination-fraction {
      bottom: 20%;
    }
    .swiper-button-next,
    .swiper-button-prev {
      top: 60% !important;
    }
    a.video__block--btn {
      bottom: 25%;
    }
  }
}

@media screen and (max-width: 1281px) and (max-height: 960px) and (min-height: 800px) {
  header.header .header__bottom .favorites {
    margin-right: 10px;
  }
}

// 1025
@media screen and (max-width: 1281px) {
  header.header .header__bottom nav.header__menu ul li a {
    font-size: 12px;
  }

  header.header .header__bottom.sticky-menu .header__phone a {
    font-size: 14px !important;
  }

  header.header .header__bottom.sticky-menu .header__phone .header__call a {
    font-size: 12px !important;
  }

  header.header .header__bottom.sticky-menu .btn,
  .header__bottom a.btn {
    padding-top: 0 !important;
    height: 42px !important;
    font-size: 12px !important;
    width: auto !important;
    white-space: nowrap;
    line-height: 42px;
  }

  header.header .header__bottom .favorites .msfavorites-total-all {
    width: 35px;
  }

  section {
    padding: 90px 0 160px;
  }

  section.about {
    padding: 60px 0 60px;
    .mt__slider {
      margin-top: 0;
    }
  }

  section.consultation {
    padding: 60px 0 60px;
    .map-content {
      margin: 0 auto;
    }
    button {
      margin-top: 0;
    }
  }

  section h2.section__title {
    font-size: 60px;
  }

  section.s-advantages .section__pretitle {
    font-size: 26px;
  }

  section.s-technology .s-technology-wrap .tab-content {
    padding: 40px;
  }

  section.s-advantages .s-first .slider-section {
    margin-top: 0;
  }

  section.video__block .video__content .description {
    font-size: 14px;
  }

  section.video__block
    .swiper-container-horizontal
    > .swiper-pagination-bullets,
  section.video__block .swiper-pagination-custom,
  section.video__block .swiper-pagination-fraction {
    bottom: 15%;
  }

  section.video__block a.video__block--btn {
    bottom: 17%;
  }

  .project__item--body .top .title {
    font-size: 20px;
  }

  .go-msfavorites,
  a.msfavorites {
    font-size: 12px;
  }

  section.consultation .map-content #map {
    width: 450px;
  }
  section.video__block {
    height: auto;
    border-bottom: 0;
    .swiper-button-next,
    .swiper-button-prev {
      top: 70% !important;
    }
    .video__content {
      .wrap__container .btn_wrap a {
        height: 50px;
        line-height: 50px;
      }

      .description {
        margin-top: 0;
      }
      .title {
        font-size: 40px;
      }
      .subtitle {
        font-size: 22px;
      }
      .description {
        font-size: 12px;
      }
      .wrap__container {
        .btn_wrap {
          width: 40%;
        }
        .description {
          width: 60%;
          padding-right: 20px;
        }
      }
    }
  }
}

@media (min-width: 500px) and (max-width: 1000px) {
  section.about .headtitle {
    margin-top: 40px;
  }
  section.consultation .map-content {
    margin-bottom: 40px;
  }
}

@media (min-width: 768px) and (max-width: 1000px) {
  section.s-advantages {
    .s-odd {
      .left_s {
        order: 2;
      }
      .right_s {
        order: 1;
      }
    }
    .s-advantages-wrap .headtitle {
      margin-top: 20px;
    }
  }
  section.s-technology .s-technology-wrap .tab-content {
    .left__col {
      order: 2;
      width: 100%;
    }
    .right__col {
      order: 1;
      width: 100%;
    }
  }
  .wrap__two-button {
    margin-bottom: 30px;
  }

  .project__description--top .icon__box {
    font-size: 14px;
  }

  section.video__block {
    border-bottom: 0;
    height: auto;
  }

  section.video__block {
    height: auto;
    .swiper-container-horizontal > .swiper-pagination-bullets,
    .swiper-pagination-custom,
    .swiper-pagination-fraction {
      bottom: 5%;
    }
    .video__content {
      top: 40%;
      .title {
        font-size: 40px;
      }
      .subtitle {
        font-size: 22px;
      }
      .description {
        font-size: 12px;
      }
      .wrap__container {
        .btn_wrap {
          width: 40%;
        }
        .description {
          width: 60%;
        }
      }
    }
  }
}

// ≤992px

@media (max-width: 992px) {
  header.header .header__top,
  header.header .header__bottom {
    display: none;
  }

  header.header .header__mobile {
    display: block;
  }

  section.consultation .map-content #map {
    width: 100%;
  }

  section.video__block {
    height: auto;

    .fullscreen-bg video {
      border-bottom: 0 !important;
    }
  }

  .fotorama__video iframe {
    //min-height: 195px;
    height: 100%;
  }

  .fotorama__video-play {
    width: 70px;
    height: 70px;
  }

  header.header {
    height: 85px;

    &.is-hidden {
      transform: none;
    }
  }

  .wrapper {
    margin-top: 70px;
  }

  section.content-about {
    .slider-about {
      margin-top: 0;
      .item {
        padding: 0 20px;
      }
    }
    .content-about__wrap .col-right {
      padding-top: 0;
      .headtitle {
        font-size: 26px;
        margin-bottom: 20px;
      }
      .subtitle {
        font-size: 20px;
        margin-bottom: 20px;
      }
      p.big {
        font-size: 14px;
        margin-bottom: 20px;
      }
    }
  }
  section.reviews .review__item {
    p.big {
      font-size: 14px;
    }
  }
}

// ≤768px
@media (max-width: 768px) {
  section.s-advantages {
    .s-odd {
      .left_s {
        order: 2;
      }
      .right_s {
        order: 1;
      }
    }
    .s-advantages-wrap .headtitle {
      margin-top: 20px;
    }
  }

  section.video__block .video__content .wrap__container .btn_wrap {
    width: auto;
    margin: 0 auto;
  }

  h1 {
    font-size: 26px;
    line-height: 120%;
    text-transform: uppercase;
  }

  h2 {
    font-size: 20px;
    line-height: 122%;
  }

  h3 {
    font-size: 18px;
  }

  h4 {
    font-size: 10px;
  }

  h5 {
    font-size: 11px;
    line-height: 13px;
  }

  p {
    font-size: 11px;

    &.big {
      font-size: 15px;
    }
  }

  .mt__image {
    margin: -160px auto 0;
  }

  .swiper-button-next,
  .swiper-button-prev {
    display: none !important;
  }

  section.consultation {
    .map-content {
      margin-bottom: 60px;
      width: 100% !important;
    }
    .ymaps-2-1-76-map {
      width: 100% !important;
    }
  }

  section.reviews .review__item {
    padding-left: 20px;
    padding-right: 20px;
  }

  .no__margin {
    padding: 40px 0;

    h1.title {
      margin-bottom: 30px;
    }
  }

  .mt__image {
    margin-bottom: 40px;
  }

  .title__wrap {
    padding: 40px 0 120px;
  }

  ul.list--marked {
    margin-left: 10px;

    li {
      font-size: 11px;

      &::after {
        left: 3px;
      }
    }
  }

  .arrow--link {
    font-size: 12px;
    line-height: 100%;

    &::before {
      top: -5px;
      right: -30px;
    }
  }

  .whatsapp,
  .viber,
  .telegram {
    a {
      font-size: 11px;
      line-height: 13px;
    }
  }

  section {
    padding: 60px 0 160px;

    .s__title {
      margin-bottom: 40px;

      .arrow--link {
        margin-right: 0;
      }
    }

    h2.section__title {
      font-size: 30px;
      line-height: 120%;

      span {
        font-size: 20px;
        line-height: 122%;
      }
    }
  }

  section.video__block {
    height: 100vh;
    min-height: 500px;
    a.video__block--btn {
      bottom: 15%;
    }
  }

  .projects__wrap .title__wrap {
    h1 {
      font-size: 20px;
      text-transform: none;
    }
    h2 {
      font-size: 18px !important;
      text-transform: none;
    }
    .subtitle {
      margin-top: 20px;
    }
  }

  .s-technology-wrap .slider-item {
    padding: 0;
  }
}

@media only screen and (max-width: 641px) {
  header.header .header__mobile .logo img {
    max-width: 110px;
  }

  section.video__block .video__content {
    .title {
      font-size: 60px;
    }

    .subtitle {
      margin-bottom: 20px;
    }
  }
  section.s-technology .info {
    font-size: 14px;
  }

  section.s-technology .s-technology-wrap .tab-content .left__col {
    .tab__title {
      margin-bottom: 30px;
    }
    .tab__text {
      font-size: 14px;
      margin-bottom: 30px;
    }
  }
  section.content-about .content-about__wrap .col-left,
  section.content-about .content-about__wrap .col-right {
    width: 100%;
  }
  section.content-about .content-about__wrap {
    img {
      margin: 0 auto 30px;
      max-width: 450px;
    }
  }
}

@media only screen and (max-width: 500px) {
  section.content-about .content-about__wrap {
    img {
      margin: 0 auto 20px;
      max-width: 100%;
    }
    .col-right {
      padding-top: 15px;
      .headtitle {
        font-size: 24px !important;
      }
    }
  }
}

// ≤600px
@media (max-width: 600px) {
  header.header {
    height: 85px;
  }

  section.video__block .fullscreen-bg {
    min-height: 500px;
  }

  section {
    padding: 60px 0 60px;
  }

  section.reviews {
    padding-top: 0;
    &.mt-mobile {
      margin-top: 40px;
    }
  }

  section.big-map h2 {
    margin-bottom: 30px;
  }

  section.content-about {
    margin-top: 140px;
    &.mb-mobile {
      margin-top: 0;
      margin-bottom: 60px;
    }
    .item {
      padding-left: 20px;
      padding-right: 20px;
    }
    .content-about__wrap {
      flex-direction: column;
      .col-left,
      .col-right {
        width: 100%;
      }
      .col-right {
        padding-top: 30px;
        .headtitle {
          margin-bottom: 20px;
          font-size: 26px;
        }
        .subtitle {
          font-size: 18px;
          margin-bottom: 20px;
        }
        p.big {
          font-size: 14px;
          margin-bottom: 20px;
        }
      }
    }
  }

  .slider-item__title {
    font-size: 18px;
  }

  section.s-technology {
    padding-top: 0;
    padding-bottom: 60px;
    .s-technology-wrap .tab-content {
      padding: 20px;
    }
  }

  section.s-advantages {
    padding: 60px 0 60px;
    .s-first,
    .s-odd,
    .s-odd {
      padding-top: 40px;
      padding-bottom: 40px;
    }
    .section__title {
      font-size: 26px;
      margin-bottom: 20px;
    }
    .section__pretitle {
      font-size: 18px;
    }
    .s-advantages-wrap {
      margin-top: 0;
      .headtitle {
        margin-bottom: 20px;
      }
    }
  }

  section.video__block .video__content {
    .title {
      font-size: 60px;
    }

    .subtitle {
      margin-bottom: 20px;
    }
  }

  .plane__wrap .plane-plus {
    bottom: 20px;
    right: 20px;
    width: 50px;
    height: 50px;
  }

  .project-modal {
    padding-right: 0 !important;
    .modal-header {
      border-radius: 0;
      .close {
        font-size: 35px;
        height: 50px;
        width: 50px;
      }
    }
    .modal-body {
      padding: 30px 20px 30px;
      .title {
        font-size: 18px;
        margin-bottom: 20px;
      }
      .text {
        font-size: 12px;
      }
    }
    .modal-content {
      border-radius: 0;
    }
  }

  section.s-technology .s-technology-wrap .nav-tabs {
    justify-content: space-between;
    .nav-item {
      margin-right: 0;
      width: 48%;
      text-align: center;
      margin-bottom: 10px;
    }

    .nav-link {
      font-size: 13px;
    }
  }
}

@media (max-width: 412px) {
  section.video__block .video__content .title {
    font-size: 42px;
  }
}

// ≤ 360px

@media (max-width: 320px) {
  h1,
  h2,
  h3,
  h4 {
    font-style: normal;
    text-transform: none;
  }

  header.header .header__mobile {
    //display: none;
    padding: 10px 0;
  }

  .project__description--bottom .btn__wrap .btn {
    font-size: 12px;
  }
}

@media only screen and (min-device-width: 320px) and (max-device-width: 568px) and (orientation: landscape) {
  header.header .header__mobile .logo img {
    max-width: 100px;
  }
}

/*==========  Mobile First  ==========*/

// ≥576px
@include media-breakpoint-up(sm) {
}

@media (min-width: 630px) {
  .container {
    max-width: 600px;
  }
}

// ≥768px
@media (min-width: 769px) {
}

// ≥800px
@media (min-width: 768px) and (max-width: 1024px) {
}

// ≥992px
@media (min-width: 993px) {
  header.header .header__mobile {
    display: none;
  }
  header.header {
    &.is-hidden {
      .header__bottom nav > ul > li > a {
        padding: 0;
        line-height: initial;
      }
    }
  }
}

// ≥1200px
@include media-breakpoint-up(xl) {
}

// ≥1430px
@media (min-width: 1200px) {
  .container {
    max-width: 1200px;
  }
}
