header.header {
  position: fixed;
  z-index: 4;
  top: 0;
  left: 0;
  width: 100%;
  height: 136px;
  background-color: #ffffff;
  transform: translateZ(0);
  will-change: transform;
  transition: transform 0.5s;

  &::after {
    clear: both;
    content: '';
    display: block;
  }

  &.is-hidden {
    height: 60px;
    transform: none;
    .header__top {
      display: none;
    }
    .header__bottom {
      padding: 10px 0;
      .btn {
        height: 40px;
        padding-top: 14px;
        padding-bottom: 14px;
      }
      .logo img {
        max-height: 40px;
      }
      .mega-menu::before {
        top: 60px;
      }
    }
  }

  .container {
    max-width: 100%;
  }

  .header__top {
    background-color: $gray;
    padding: 13px 0;

    a {
      display: block;
      color: white;
      font-size: 15px;
      line-height: 18px;
      letter-spacing: 0.02em;
    }

    .header__mail,
    .header__whatsapp,
    .header__phone {
      display: flex;
      align-items: center;
      margin-right: 30px;

      a {
        margin-left: 10px;
      }
    }

    .header__call {
      a {
        color: $orange;
      }
    }
  }

  .header__bottom {
    padding: 14px 0;

    .dropdown-item.active,
    .dropdown-item:active {
      color: #fff !important;
      text-decoration: none;
      background-color: $orange;
    }

    .dropdown-item:hover,
    .dropdown-item:focus {
      color: white;
      background-color: $orange;
    }

    .msfavorites-total {
      font-size: 13px;
    }

    .dropdown-toggle::after {
      display: inline-block;
      margin-left: 0;
      vertical-align: 0;
      content: '';
      border-top: 0.3em solid;
      border-right: 0.3em solid transparent;
      border-bottom: 0;
      border-left: 0.3em solid transparent;
      position: absolute;
      bottom: -10px;
      left: 50%;
      color: #a2a7a9;
    }

    .logo img {
      height: 63px;
    }

    nav.header__menu {
      ul {
        li {
          //margin-right: 15px;

          &:hover {
            ul.dropdown {
              display: block;
            }
          }

          &:last-child {
            margin-right: 0;
          }

          a {
            line-height: 150%;
            color: #000000;
          }
        }

        .drop {
          position: relative;

          &::before {
            content: '';
            position: absolute;
            content: '';
            top: 35px;
            left: 50%;
            transform: translate(-50%, -50%);
            border: 6px solid transparent;
            border-top: 7px solid #a2a7a9;
          }

          ul.dropdown {
            width: 100%;
            background: transparent;
            display: none;
            position: absolute;
            z-index: 999;
            left: 0;
            top: 40px;

            li {
              display: block;
            }
          }
        }
      }
    }

    .favorites {
      display: flex;
      align-items: center;
      margin-right: 15px;

      .msfavorites-total-all {
        color: #4e4c4b;

        &:hover {
          text-decoration: none;
        }
      }

      sub {
        margin-left: 5px;
      }
    }
  }

  .header__toggle {
    .header__toggle--mobile {
      flex-direction: column;
      cursor: pointer;

      span {
        display: block;
        width: 32px;
        height: 3px;
        margin-bottom: 5px;
        position: relative;
        background: $black;
        border-radius: 0;
        z-index: 1;
        transform-origin: 4px 0;
        transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1),
          background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1), opacity 0.55s ease;

        &:first-child {
          transform-origin: 0% 0%;
        }

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }

  .header__mobile {
    display: none;
    padding: 15px 0;

    .logo {
      img {
        max-width: 150px;
        width: 100%;
      }
    }

    .header__phone {
      a {
        color: $gray;
        font-family: 'Montserrat-SemiBold', serif;
        font-size: 14px;
        letter-spacing: 0.02em;
      }
    }

    .write-social .whatsapp,
    .write-social .viber,
    .write-social .telegram {
      margin-bottom: 0;
    }
  }
}

.menu {
  background-color: white;
  position: fixed;
  height: 100vh;
  left: 100%;
  opacity: 1;
  width: 100vw;
  transition: 0.3s;
  z-index: 11;
  display: flex;
  flex-direction: column;

  &.active {
    left: 0;
    top: 0;
    overflow: auto;
  }

  &__top {
    padding: 15px 0;

    .header__phone {
      a {
        color: $gray;
        font-family: 'Montserrat-SemiBold', serif;
        font-size: 14px;
        letter-spacing: 0.02em;
      }
    }

    .menu__button {
      .header-close {
        cursor: pointer;
        position: absolute;
        right: 15px;
        top: 10px;

        img {
          width: 25px;
          height: auto;
        }
      }
    }
  }

  &__navs {
    margin: 10px 0 20px;
    flex-grow: 1;

    .menu__list {
      .menu__list--item {
        margin-bottom: 15px;

        .menu__list--link {
          font-family: 'Montserrat-SemiBold', serif;
          font-size: 18px;
          line-height: 120%;
          letter-spacing: 0.02em;
          color: $black;
        }

        .drop__menu {
          background-color: transparent;
          border: 0;
          padding-top: 20px;
          padding-bottom: 0;
          padding-left: 20px;

          li {
            padding-bottom: 10px;
            position: relative;

            &::after {
              content: '';
              position: absolute;
              content: '';
              top: 5px;
              left: -15px;
              border: 6px solid transparent;
              border-left: 7px solid #a2a7a9;
            }

            &:last-child {
              padding-bottom: 0;
            }

            a {
              font-family: 'Montserrat', serif;
              font-size: 18px;
              line-height: 120%;
              letter-spacing: 0.02em;
              color: $black;
            }
          }
        }
      }
    }

    .favorites__block {
      margin-bottom: 30px;

      a {
        color: $orange;
      }

      span.msfavorites-total {
        font-size: 13px;
      }

      span.name {
        margin-left: 12px;
        font-family: 'Montserrat-SemiBold', serif;
        font-size: 18px;
        line-height: 120%;
        letter-spacing: 0.02em;
        color: $black;
      }
    }
  }

  &__footer {
    background-color: $gray;
    padding: 30px 20px;

    .container {
      padding-left: 0;
      padding-right: 0;
    }

    &--item {
      margin-bottom: 20px;
      display: flex;
      align-items: center;

      &:last-child {
        margin-bottom: 0;
      }

      span {
        width: 16px;
      }

      a {
        color: white;
        font-family: 'Montserrat-SemiBold', serif;
        font-size: 11px;
        line-height: 13px;
        letter-spacing: 0.02em;
        margin-left: 10px;

        &.orange {
          color: $orange;
        }
      }
    }
  }
}

header.header .header__bottom {
  .center {
    justify-content: center;
  }

  .btn {
    width: 220px;
  }

  .header__phone {
    display: none;
  }

  &.sticky-menu {
    background-color: #f5f3ed;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 5;
    visibility: visible;
    transition-delay: 0.1s;
    height: auto;
    padding: 10px 0;
    border-bottom: 0.5px solid #a2a7a9;

    .center {
      justify-content: space-around;
    }

    .header__phone {
      display: block;

      a {
        font-family: 'Montserrat-SemiBold', serif;
        font-size: 18px;
        line-height: 120%;
        letter-spacing: 0.02em;
        color: $gray;
      }

      .header__call {
        a {
          color: $orange;
          font-size: 15px;
          text-align: center;
          display: block;
        }
      }
    }

    .logo {
      img {
        max-height: 63px;
      }
    }

    .btn {
      padding-top: 16px;
      padding-bottom: 0;
      height: 45px;
    }
  }
  .ul-reset {
    padding-left: 0;
    margin-top: 0;
    margin-bottom: 0;
    list-style: none;
    position: relative;
  }

  /* #Navigation Styles
  –––––––––––––––––––––––––––––––––––––––––––––––––– */
  nav {
    font-size: 0;
    position: relative;
    width: 100%;
    & > ul > li {
      display: inline-block;
      font-size: 14px;
      padding: 0 15px;
      position: relative;
      line-height: 150%;
      letter-spacing: 0.02em;
      &:first-child {
        padding-left: 0;
      }
      &:last-child {
        padding-right: 0;
      }
      > a {
        display: block;
        position: relative;
        padding: 10px;
        border-bottom: 4px solid transparent;
        white-space: nowrap;
      }
      &:hover > a {
        text-decoration: none;
        color: #fa5838;
        //border-bottom: 4px solid #fa5838;
      }
    }
  }

  /* #Mega Menu Styles
  –––––––––––––––––––––––––––––––––––––––––––––––––– */
  .mega-menu {
    display: none;
    left: 0;
    position: absolute;
    text-align: left;
    width: 100%;
    min-width: 1000px;
    &::before {
      content: '';
      position: fixed;
      background: #fff;
      width: 100%;
      height: calc(100% + 45px);
      min-height: 230px;
      left: 0;
      top: 120px;
    }
    ul {
      margin-bottom: 20px;
      &:last-child {
        margin-right: 0;
      }
    }
    a {
      font-size: 14px;
      line-height: 150%;
      color: #000;
      display: block;
      letter-spacing: 0.02em;
      margin-bottom: 10px;
      white-space: nowrap;
      &:hover {
        color: #fa5838;
        text-decoration: none;
      }
    }
    &__title,
    &__title a {
      color: #fa5838;
      font-family: 'Montserrat-SemiBold', serif;
      font-size: 13px;
      line-height: 100%;
      letter-spacing: 0.04em;
      margin-bottom: 20px;
      text-transform: uppercase;
    }
    &__etag {
      position: relative;
      &::before {
        position: absolute;
        content: '';
        background-image: url(../images/general/menu_etag.svg);
        background-size: cover;
        height: 20px;
        width: 20px;
        top: 0;
        left: -30px;
      }
    }
    &__dop {
      position: relative;
      &::before {
        position: absolute;
        content: '';
        background-image: url(../images/general/menu_dop.svg);
        background-size: cover;
        height: 18px;
        width: 18px;
        top: 0;
        left: -30px;
      }
    }
    &__osobenosti {
      position: relative;
      &::before {
        position: absolute;
        content: '';
        background-image: url(../images/general/menu_osobenosti.svg);
        background-size: cover;
        height: 18px;
        width: 18px;
        top: 0;
        left: -30px;
      }
    }
    &__material {
      position: relative;
      &::before {
        position: absolute;
        content: '';
        background-image: url(../images/general/menu_material.svg);
        background-size: cover;
        height: 20px;
        width: 20px;
        top: 0;
        left: -30px;
      }
    }
    &__blog {
      position: relative;
      &::before {
        position: absolute;
        content: '';
        background-image: url(../images/general/menu_blog.svg);
        background-size: cover;
        height: 20px;
        width: 20px;
        top: 0;
        left: -30px;
      }
    }
    &__built_houses {
      position: relative;
      &::before {
        position: absolute;
        content: '';
        background-image: url(../images/general/menu_built_houses.svg);
        background-size: cover;
        height: 20px;
        width: 20px;
        top: 0;
        left: -30px;
      }
    }
    &__company {
      position: relative;
      &::before {
        position: absolute;
        content: '';
        background-image: url(../images/general/menu_company.svg);
        background-size: cover;
        height: 20px;
        width: 20px;
        top: 0;
        left: -30px;
      }
    }
    &__design {
      position: relative;
      &::before {
        position: absolute;
        content: '';
        background-image: url(../images/general/menu_design.svg);
        background-size: cover;
        height: 20px;
        width: 20px;
        top: 0;
        left: -30px;
      }
    }
    &__info {
      position: relative;
      &::before {
        position: absolute;
        content: '';
        background-image: url(../images/general/menu_info.svg);
        background-size: cover;
        height: 20px;
        width: 20px;
        top: 0;
        left: -30px;
      }
    }
    &__sovetinform {
      position: relative;
      &::before {
        position: absolute;
        content: '';
        background-image: url(../images/general/menu_sovetinform.svg);
        background-size: cover;
        height: 20px;
        width: 20px;
        top: 0;
        left: -30px;
      }
    }
    &__special_offers {
      position: relative;
      &::before {
        position: absolute;
        content: '';
        background-image: url(../images/general/menu_special_offers.svg);
        background-size: cover;
        height: 20px;
        width: 20px;
        top: 0;
        left: -30px;
      }
    }
  }

  .wrap {
    display: flex;
    padding-top: 40px;
    width: 100%;
    min-width: 100%;
    .ul-reset {
      width: 300px;
      width: 25%;
    }
  }

  /* #Droppable Class Styles
  –––––––––––––––––––––––––––––––––––––––––––––––––– */
  .droppable {
    position: static;
  }
  .droppable > a:after {
    content: '';
    position: relative;
    top: 15px;
    left: 10px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 7px 7px 0 7px;
    border-color: #a2a7a9 transparent transparent transparent;
  }
  .droppable:hover .mega-menu {
    display: block;
  }

  /* #Browser Clearfix
  –––––––––––––––––––––––––––––––––––––––––––––––––– */
  // .cf:before,
  // .cf:after {
  //   content: ' '; /* 1 */
  //   display: table; /* 2 */
  // }
  // .cf:after {
  //   clear: both;
  // }
}

@media (max-width: 768px) {
  header.header {
    height: 75px !important;

    .header__top,
    .header__bottom {
      display: none;
    }

    .header__mobile {
      display: block;

      &.sticky-menu {
        background-color: #f5f3ed;
        position: fixed;
        top: 0;
        width: 100%;
        z-index: 6;
        visibility: visible;
        transition-delay: 0.1s;
        height: auto;
        padding: 10px 0;
        border-bottom: 0.5px solid #a2a7a9;
      }

      .menu__top {
        .menu__button .header-close {
          top: 20px;
        }

        .header__phone a {
          font-size: 18px;
        }
      }

      .header__messagers {
        display: flex;

        .headtitle {
          margin-right: 10px;
          font-size: 12px;
        }

        .write-social {
          a {
            margin-right: 5px;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 823px) and (orientation: landscape) {
  .menu__top {
    .menu__button .header-close {
      top: 30px;
    }

    .header__phone a {
      font-size: 20px;
    }
  }

  header.header .header__top,
  header.header .header__bottom {
    display: none;
  }

  header.header {
    .header__top,
    .header__bottom {
      display: none;
    }

    .header__mobile {
      display: block;

      .header__messagers {
        .headtitle {
          margin-right: 10px;
        }
      }

      .write-social .whatsapp,
      .write-social .viber,
      .write-social .telegram {
        margin-right: 5px;
      }
    }
  }
}

@media (max-width: 540px) {
  header.header {
    height: 72px;
  }
}

@media (max-width: 375px) {
  header.header {
    height: 72px !important;
  }
}

@media (max-width: 360px) {
  .menu__navs .menu__list {
    .menu__list--item {
      margin-bottom: 10px;

      .drop__menu {
        padding-top: 10px;
      }
    }
  }

  .menu__footer--item {
    margin-bottom: 15px;
  }
}
