@import 'utils/libs';
@import 'utils/vars';
@import 'utils/mixins';
@import 'utils/fonts';

::placeholder {
  color: $black;
}

::selection {
  background-color: $black;
  color: $white;
}

html {
  scroll-behavior: smooth;
}

body {
  font-size: 16px;
  min-width: 320px;
  position: relative;
  line-height: 1.5;
  font-family: $default-font;
  overflow-x: hidden;
  color: $black;
  background: $white;
  outline: 0;
}

input,
textarea {
  outline: none;
  cursor: pointer;

  &:focus:required:invalid {
    border-color: red;
  }

  &:required:valid {
    border-color: green;
  }
}

a {
  cursor: pointer;
  text-decoration-color: $orange;
  transition: color 0.3s ease;

  &:hover {
    color: $orange;
    text-decoration: underline;
  }
}

.img-responsive {
  display: block;
  max-width: 100%;
  height: auto;

  &.img-width {
    width: 100%;
  }
}

.text-center {
  text-align: center !important;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.mx-auto {
  margin-right: auto !important;
  margin-left: auto !important;
}

b,
strong {
  font-weight: bold;
}

.container {
  margin: 0 auto;
  width: 100%;
}

ul {
  margin: 0;
  padding: 0;
  list-style-type: none;

  &.list {
    li {
      &:not(last-child) {
        margin-bottom: 5px;
      }

      a {
        color: #383838;
        font-size: 16px;
        line-height: 32px;
      }
    }
  }
}

input:focus {
  outline: 0;
  box-shadow: none;
}

.no__margin {
  padding: 100px 0;

  h1.title {
    margin-bottom: 60px;
  }
}

.tt__none {
  text-transform: none;
}

ul.list--marked {
  margin-left: 20px;

  li {
    line-height: 180%;
    letter-spacing: 0.02em;
    padding-inline-start: 40px;
    position: relative;

    &::after {
      content: '';
      position: absolute;
      content: '';
      top: 8px;
      left: 10px;
      border: 6px solid transparent;
      border-left: 7px solid #a2a7a9;
    }
  }
}

.custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: $orange;
  background-color: $orange;
}

.custom-control-label::before {
  background-color: transparent;
  border: #adb5bd solid 2px;
}

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: none;
}

section {
  padding: 90px 0 200px;
  position: relative;

  &.bb__no {
    border-bottom: 0 !important;
  }

  .s__title {
    margin-bottom: 60px;

    .arrow--link {
      margin-right: 35px;
    }
  }

  h2.section__title {
    color: $black;
    font-family: 'Montserrat-Bold';
    font-size: 80px;
    line-height: 90%;

    span {
      display: block;
      font-size: 50px;
      color: $orange;
    }
  }

  .info {
    font-size: 16px;
    letter-spacing: 0.02em;
    margin-top: 60px;
    max-width: 800px;
  }
}

.title__wrap {
  padding: 100px 0 160px;

  .subtitle {
    margin-top: 60px;
  }
}

h1,
h2,
h3,
h4 {
  font-style: normal;
  text-transform: uppercase;
}

h1 {
  font-family: 'Montserrat-Bold';
  font-weight: bold;
  font-size: 45px;
  line-height: 90%;
  letter-spacing: 0.02em;
}

h2 {
  font-family: 'Montserrat-Bold';
  font-weight: bold;
  font-size: 41px;
  line-height: 120%;
  letter-spacing: 0.02em;
}

h3 {
  font-family: 'Montserrat-SemiBold';
  font-weight: 600;
  font-size: 34px;
  line-height: 120%;
}

h4 {
  font-family: 'Montserrat-SemiBold';
  font-weight: 600;
  font-size: 13px;
  line-height: 100%;
  letter-spacing: 0.04em;
}

h5 {
  font-family: 'Montserrat-SemiBold';
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 18px;
  letter-spacing: 0.02em;
}

p {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
  letter-spacing: 0.02em;

  &.big {
    font-size: 20px;
  }
}

.mt__image {
  margin-top: -160px;
}

.pb__100 {
  padding-bottom: 100px !important;
}

.ic__whatsapp {
  content: url(../images/general/ic_whatsapp.svg);
  width: 16px;
  height: 16px;
}

.ic__viber {
  content: url(../images/general/ic_viber.svg);
  width: 16px;
  height: 18px;
}

.ic__telegram {
  content: url(../images/general/ic_telegram.svg);
  width: 16px;
  height: 18px;
}

.ic__vk {
  content: url(../images/general/ic_vk.svg);
  width: 19px;
  height: 11px;
}

.ic__inst {
  content: url(../images/general/ic_inst.svg);
  width: 16px;
  height: 16px;
}

.ic__fb {
  content: url(../images/general/ic_fb.svg);
  width: 9px;
  height: 17px;
}

.ic__yt {
  content: url(../images/general/ic_ytb.svg);
  width: 19px;
  height: 16px;
}

.ic__dzen {
  content: url(../images/general/ic_dzen.png);
  width: 16px;
  height: 16px;
}

.ic__loc {
  content: url(../images/general/ic_loc.svg);
  width: 14px;
  height: 17px;
}

.ic__file {
  content: url(../images/general/ic_file.svg);
  width: 14px;
  height: 18px;
}

.ic__mail {
  content: url(../images/general/ic_mail.svg);
  width: 16px;
  height: 10px;
}

.ic__phone {
  content: url(../images/general/ic_phone.svg);
  width: 16px;
  height: 10px;
}

.ic__mail--gray {
  content: url(../images/general/ic_mail--gray.svg);
  width: 16px;
  height: 10px;
}

.ic__user--gray {
  content: url(../images/general/ic_user.svg);
  width: 14px;
  height: 15px;
}

.ic__whatsapp--gray {
  content: url(../images/general/ic_whatsapp--gray.svg);
  width: 16px;
  height: 16px;
}

.ic__phone--gray {
  content: url(../images/general/ic_phone--gray.svg);
  width: 13px;
  height: 13px;
}

.ic__heart {
  content: url(../images/general/ic_heart.svg);
  width: 16px;
  height: 15px;
}

.ic__heart--full {
  content: url(../images/general/ic_heart--full.svg);
  width: 21px;
  height: 20px;
}

.ic__garantee {
  content: url(../images/general/ic_garantee.svg);
  width: 48px;
  height: 46px;
}

.ic__price {
  content: url(../images/general/ic_price.svg);
  width: 34px;
  height: 48px;
}

.ic__transparent {
  content: url(../images/general/ic_transparent.svg);
  width: 42px;
  height: 32px;
}

.ic__design {
  content: url(../images/general/ic_design.svg);
  width: 38px;
  height: 38px;
}

.ic__arrow--top {
  content: url(../images/general/index_arrow.svg);
  width: 20px;
  height: 16px;
}

.ic__fb--color {
  content: url(../images/general/ic_fb--color.svg);
  width: 24px;
  height: 24px;
}

.ic__vk--color {
  content: url(../images/general/ic_vk--color.svg);
  width: 24px;
  height: 26px;
}

.ic__search {
  content: url(../images/general/ic_search.svg);
  width: 24px;
  height: 24px;
}

.play_y {
  content: url(../images/general/icon_play.svg);
  width: 24px;
  height: 24px;
}

.arrow--link {
  color: $orange;
  display: inline-block;
  font-family: 'Montserrat-SemiBold';
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 100%;
  letter-spacing: 0.04em;
  position: relative;
  text-transform: uppercase;

  &::before {
    position: absolute;
    content: '';
    top: 0;
    right: -40px;
    border: 8px solid transparent;
    border-left: 12px solid #fa5838;
  }
}

.voted {
  display: none;
}

.go-msfavorites {
  display: none;
  color: #000 !important;
  font-family: 'Montserrat-SemiBold';
  font-size: 13px;
  line-height: 16px;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  span {
    margin-left: 5px;
    height: 17px;
    width: 17px;
  }
  &:hover {
    text-decoration: none;
  }
}

.favorites {
  height: 17px;
  width: 36px;
}

.voted + .go-msfavorites {
  display: flex;
}

a.msfavorites {
  color: $gray;
  font-family: 'Montserrat-SemiBold';
  font-size: 13px;
  line-height: 16px;
  letter-spacing: 0.04em;
  text-transform: uppercase;

  &:hover {
    color: $orange;
  }
}

.wrapper {
  margin-top: 136px;
}

.write-social {
  .whatsapp,
  .viber,
  .telegram {
    display: flex;
    align-items: center;
    margin-bottom: 10px;

    span {
      margin-right: 10px;
    }

    a {
      color: $white;
      font-family: 'Montserrat-SemiBold', serif;
      font-weight: 600;
      font-size: 15px;
      line-height: 18px;
      letter-spacing: 0.02em;
    }
  }
}

.list__social {
  align-items: center;

  li {
    &:not(:last-child) {
      margin-right: 30px;
    }

    a {
      display: block;
      transition: opacity 0.5s ease;

      &:hover {
        opacity: 0.7;
      }
    }
  }
}

.map__wrap {
  margin-bottom: 100px;
}

.fotorama__nav {
  margin-bottom: 30px;
  margin-top: 30px;
  text-align: left;
}

.fotorama__nav__frame {
  height: 64px;
  margin-right: 20px;
  padding: 0 !important;
  border-bottom: 10px solid transparent;
  .fotorama__video-play {
    background-position: center center;
  }

  &:last-child {
    margin-right: 0;
  }

  &.fotorama__active {
    border-bottom: 10px solid $orange;
    height: 74px;
  }
}
.fotorama__thumb {
  .fotorama__video-play {
    background: url(/assets/design/images/general/play-thumb.png) !important;
    width: 32px !important;
    height: 32px !important;
    margin-left: -15px;
    margin-top: -20px;
    &:hover {
      background: url(/assets/design/images/general/play-thumb--hover.png) !important;
      width: 32px !important;
      height: 32px !important;
      margin-left: -15px;
      margin-top: -20px;
    }
  }
}

.fotorama__thumb-border {
  display: none;
}

.fotorama__arr,
.fotorama__fullscreen-icon,
.fotorama__video-close,
.fotorama__video-play {
  background: url(../images/general/fotorama.png) no-repeat;
}

.fotorama__video {
  z-index: 10;
  top: 32px;
  right: 0;
  bottom: 0;
  left: 0;
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 0;
  height: 0;
  overflow: hidden;
}

.fotorama__video iframe,
.fotorama__video object,
.fotorama__video embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.fotorama__video-close {
  display: none !important;
}

.fotorama__arr--next {
  background-position: -32px 0 !important;
  right: 40px;
}

.fotorama__arr--prev {
  left: 40px;
}

.video__container {
  position: relative;
  padding-bottom: 56.25%;
  /* padding-top: 30px; */
  height: 0;
  overflow: hidden;
}

.video__container iframe,
.video__container object,
.video__container embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100% !important;
  height: 100% !important;
}

.msfavorites-parent {
  .btn__wrap {
    margin-bottom: 40px;
  }

  table {
    thead {
      background: $turquoise;
      text-align: center;

      th {
        border: 0;
        padding: 25px 20px;
      }

      h4 {
        margin-bottom: 0;
      }
    }

    tbody {
      padding-top: 20px;

      &:before {
        line-height: 1em;
        content: '.';
        color: white;
        display: block;
      }

      tr {
        background: $light;
        border-bottom: 10px solid white;
        padding: 20px;

        td {
          border: 0;
          text-align: center;
          vertical-align: middle;

          .project_image {
            margin-right: 40px;
            width: 170px;
            height: 100px;
          }

          .project_info--title {
            margin-bottom: 20px;
          }

          .msfavorites {
            text-transform: uppercase;
            display: flex;

            i {
              margin-right: 10px;
            }

            .msfavorites-text-load,
            .msfavorites-text-remove {
              font-family: 'Montserrat-SemiBold', sans-serif;
              font-weight: 600;
              font-size: 13px;
              line-height: 100%;
              letter-spacing: 0.04em;
            }
          }

          p {
            margin-bottom: 0;
          }
        }
      }
    }
  }
}

.search__wrapper {
  margin-bottom: 100px;

  #mse2_form {
    margin-bottom: 40px;
  }

  .search-link {
    color: #000000;
  }
}

.scrollTop {
  position: absolute;
  right: 25px;
  bottom: 0;
  width: 55px;
  height: 55px;
  cursor: pointer;
  border: 0;
  outline: 0;
}

.white-block {
  padding: 18px;
  border-radius: 5px;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.07);
}

::-webkit-full-page-media {
  background: rgba(255, 255, 255, 0.5) !important;
}

.scrollTop:before {
  position: absolute;
  top: 8px;
  left: 8px;
  width: 40px;
  height: 40px;
  content: '';
  background-position: 82.96779% 48.78049%;
}

.scrollTop:before {
  background: url(../images/general/top.svg) no-repeat;
}

.scrollTop__wrapper {
  position: fixed;
  z-index: 10;
  bottom: 3vh;
  left: 0;
  display: none;
  width: 100%;
  height: 1px;
}

.swiper-button-disabled {
  display: none;
}

.slick-list,
.slick-slider,
.slick-track {
  position: relative;
  display: block;
}
.slick-slide {
  padding: 0 20px;
}

.slick-prev:before,
.slick-next:before {
  color: #fa5838;
}

.slick-dots li {
  height: 4px;
  width: 25px;
}

.slick-dots li.slick-active button {
  width: 25px;
  height: 10px;
  background: #fa5838;
  position: relative;
}

.slick-dots li button {
  background-color: #000;
  border-radius: 0;
  opacity: 1;
  margin: 0 5px !important;
  height: 4px;
  width: 24px;
}

.slick-dots li button:before {
  display: none;
}

.slick-prev {
  left: -45px;
}

.slick-next {
  right: -45px;
}

.slick-prev:before {
  content: '◄';
  font-size: 30px;
}

.slick-next:before {
  content: '►';
  font-size: 30px;
}
@media (max-width: 600px) {
  .slick-slide {
    padding: 0;
  }
}

//Модули
@import 'modules/modules';
@import 'media';
