// Colors
$white: #ffffff;
$light: #F5F3ED;
$black: #000000;
$gray: #4E4C4B;
$lightGray: #A2A7A9;
$red: #E30000;
$orange: #FA5838;
$black: #000000;
$turquoise: #CBE6E3;

// Sizes
$base-font-size: 16px;

$grid-gutter-width: 30px;
$grid-columns: 12;
$grid-breakpoints: (xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1100px);
$container-max-widths: (sm: 540px, md: 720px, lg: 960px, xl: 1100px);

$gutter: $grid-gutter-width;

// Fonts
$default-font: 'Montserrat',
sans-serif;