.modal {
  .modal-header {
    position: relative;
    border-bottom: 0;
    .modal-title {
      text-align: left;
      font-size: 16px;
    }
    .close {
      background: #fa5838;
      border: 0;
      color: white;
      font-size: 40px;
      padding: 0 !important;
      height: 55px;
      width: 55px;
      transition: background 0.3s ease;
      &:hover {
        background: $red;
      }
    }
  }
  .modal-body {
    padding: 0;
    .t-section__descr {
      font-size: 16px;
      padding: 1rem 1rem;
      margin-bottom: 0;
    }
  }
  .modal-content {
    border: 0;
    border-radius: 0;

    h4.title {
      font-size: 13px;
      line-height: 100%;
      margin-bottom: 15px;
      text-transform: uppercase;
    }

    .select__block {
      margin-bottom: 15px;
    }

    .custom-control {
      margin-bottom: 10px;

      label {
        font-style: normal;
        font-weight: 500;
        letter-spacing: 0.02em;
      }
    }

    .form-group {
      width: 100%;
      margin-bottom: 10px;

      label {
        width: 100%;
      }

      .note {
        font-size: 12px;
        line-height: 150%;
        letter-spacing: 0.02em;
        color: $gray;

        a {
          color: $orange;
        }
      }
    }

    form .form-control {
      font-size: 14px;
      padding: 18px 16px;
    }

    .btn {
      margin: 0 auto;
    }
  }

  .questions {
    padding: 0 20px 20px;

    .title {
      font-family: 'Montserrat-SemiBold', sans-serif;
      font-weight: 600;
      font-size: 30px;
      line-height: 120%;
      letter-spacing: 0.02em;
      color: #000000;
      margin-bottom: 20px;
      text-transform: none;
    }

    .nav-tabs {
      border-bottom: 0;
      justify-content: space-between;

      .nav-item {
        margin-bottom: 15px;
        border-radius: 0;

        &:last-child {
          margin-bottom: 0;
        }

        display: inline-block;
        color: black;
        cursor: pointer;
        height: 40px;
        padding-top: 10px;
        padding-bottom: 12px;
        line-height: initial;
        text-align: center;
        width: 180px;
        border: 2px solid $gray;
        box-sizing: border-box;

        &.active {
          border: 2px solid $orange;
          background: $orange;
          transition: background 0.3s ease-in;
        }
      }
    }

    .tab-content {
      padding: 0;

      .introtext {
        margin-bottom: 15px;

        p {
          font-size: 13px;
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .modal {
    padding-right: 0 !important;
    .modal-content .modal-title {
      font-size: 16px;
    }

    .modal-header .close {
      top: 5px;
      right: 10px;
      span {
        display: block;
        width: 55px;
      }
    }
    .questions {
      //padding: 0;

      .title {
        font-size: 18px;
        line-height: 120%;
        margin-bottom: 15px;
      }

      .nav-tabs .nav-item {
        margin-right: 0;
        margin-bottom: 15px;
        font-size: 11px;
        width: 145px;

        &:last-child {
          margin-bottom: 0;
        }
      }

      .tab-content {
        h4 {
          font-size: 10px;
          margin-bottom: 15px;
        }

        .introtext p {
          font-size: 12px;
        }

        form .form-control {
          font-size: 12px;
        }

        .select__block {
          margin-bottom: 15px;
        }

        .custom-control label {
          font-size: 11px;
        }

        button {
          width: 100%;
        }
      }
    }
  }
}
