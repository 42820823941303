footer.footer {
  border-top: 10px solid $orange;

  .footer--top {
    background-color: $gray;
    padding: 60px 0;

    .footer__logo {
      img {
        height: 63px;
      }
    }

    .footer__contact {
      a {
        color: $white;
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 150%;
        letter-spacing: 0.02em;
      }
    }
  }

  .footer--middle {
    background-color: $light;
    padding: 60px 0 10px;

    .nav__headline {
      color: $lightGray;
      margin-bottom: 28px;
    }

    nav.footer--menu {
      .list {
        li {
          &:not(:last-child) {
            margin-bottom: 15px;
          }

          a {
            font-family: Montserrat;
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 150%;
          }

          ul {
            margin-left: 20px;
            margin-top: 10px;

            li {
              &:not(:last-child) {
                margin-bottom: 0;
              }

              a {
                font-size: 13px;
                letter-spacing: 0.02em;
              }
            }
          }
        }
      }
    }

    .footer__address {
      &--item {
        position: relative;
        margin-inline-start: 28px;

        &::after {
          position: absolute;
          content: '';
          background-image: url(../images/general/ic_loc.svg);
          background-size: cover;
          width: 14px;
          height: 17px;
          left: -25px;
          top: 4px;
        }

        &:not(:last-child) {
          margin-bottom: 15px;
        }

        a {
          color: #000000;
        }
      }
    }

    .footer__blog {
      &--item {
        margin-bottom: 15px;
        margin-inline-start: 28px;
        position: relative;

        a {
          color: $black;
          font-style: normal;
          font-weight: 500;
          line-height: 150%;
          letter-spacing: 0.02em;
        }

        &::after {
          position: absolute;
          content: '';
          background-image: url(../images/general/ic_doc.svg);
          background-size: cover;
          width: 15px;
          height: 15px;
          left: -25px;
          top: 4px;
        }
      }

      a.arrow--link {
        margin-top: 35px;
      }
    }
  }

  .footer--bottom {
    background-color: $light;
    padding: 60px 0;

    .footer__copiryte {
      color: $black;
      font-style: normal;
      font-size: 14px;
      line-height: 150%;
      letter-spacing: 0.02em;

      a {
        color: $orange;
      }
    }
  }
}

@media (max-width: 768px) {
  footer.footer {
    .footer--top {
      padding: 40px 0;

      .footer__logo {
        display: flex;
        justify-content: center;
        margin-bottom: 40px;

        img {
          max-width: 140px;
        }
      }

      .footer__contact {
        text-align: center;
        margin-bottom: 40px;

        a {
          font-size: 15px;
        }
      }

      .write-social {
        text-align: center;

        .whatsapp,
        .viber,
        .telegram {
          justify-content: center;
        }
      }
    }

    .footer--middle {
      padding: 20px 0;

      .nav__headline {
        font-size: 10px;
        line-height: 100%;
        margin-bottom: 20px;
      }

      nav.footer--menu {
        margin-bottom: 20px;

        .list {
          li {
            &:not(:last-child) {
              margin-bottom: 5px;
            }

            a {
              font-size: 11px;
              line-height: 150%;
            }

            ul {
              margin-left: 0;

              li a {
                font-size: 10px;
                line-height: 180%;
              }
            }
          }
        }
      }

      .footer__address {
        &--item {
          font-size: 11px;
          line-height: 150%;
        }
      }

      .footer__blog {
        &--item {
          a {
            font-size: 11px;
            line-height: 150%;
          }
        }

        a.arrow--link {
          margin-top: 23px;
        }
      }
    }

    .footer--bottom {
      padding-top: 20px;
      padding-top: 30px;

      .list__social {
        margin-bottom: 40px;
      }

      .footer__copiryte {
        font-size: 11px;
        line-height: 150%;

        br {
          display: none;
        }
      }
    }
  }
}
